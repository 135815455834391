import React from "react";
import SEO from "../components/utilities/SEO";
import Layout from "../components/layout/Layout";
import ContentTitle from "../components/content-title/ContentTitle";
import ContentWrapper from "../components/content-wrapper/ContentWrapper";
import Col from "react-bootstrap/Col";
import "./kontakt.scss";

const AboutPage = () => (
  <Layout>
    <SEO
      title="Meist"
      description="ARMACOM OÜ on eksisteerinud juba aastast 2006. Pakume metsandusega seonduvaid teenuseid ning teostame metsakinnistute ostu. "
    />
    <ContentWrapper>
      <Col xs={12}>
        <ContentTitle title={"Meist"} />
        <p className="text-center text-justify-md mt-4 about-us-text">
          ARMACOM OÜ on eksisteerinud juba aastast 2006. Pakume metsandusega
          seonduvaid teenuseid ning teostame metsakinnistute ostu. Oleme Eestis
          tugevamate hinnapakkujate seas ostu-müügi teostamisel ning pakume oma
          teenust asukohast sõltumata.
          <br />
          <br />
          Oleme väike ettevõte, mis just kliendi jaoks on boonus - meie enda
          kulud on väiksed, seega saame oma klientidele võimaldada parimat hinda
          ja personaalset teenindamist.
        </p>
      </Col>
    </ContentWrapper>
  </Layout>
);

export default AboutPage;
